<template>
    <div>
        <b-row>
            <b-col>
                <p class="label-color font-size-print">
                    Công Ty TNHH Công Nghệ Di Động Việt 75/35 Nguyễn Cửu Vân, Phường 17, Q.Bình Thạnh, Tp.HCM MST:
                    0312193244
                </p>
            </b-col>
            <b-col></b-col>
            <b-col>
                <p class="label-color font-size-print text-right">
                    Mẫu số 08a - TT
                    (Ban hành theo Thông tư
                    số: 133/2016/TT-BTC
                    ngày 26/08/2016 của BTC)
                </p>
            </b-col>
        </b-row>
        <b-row>
            <div style="margin-left: 20px; margin-right: 20px; width: 100%">

                <h5 class="label-color text-center font-size-print mb-2" style="font-size: 20px;">
                    BIÊN BẢN CHỐT KIỂM KÊ
                </h5>
                <p class="label-color text-center font-size-print mb-4">
                    Địa điểm kiểm kê:
                    {{ productStockCheck.storeName }}
                </p>
                <p class="m-0 font-size-print ">
                    Thời gian kiểm kê: {{ comvertTime(productStockCheck.createdAt) }}
                </p>

                <p class=" m-0 label-color font-size-print ">
                    Ban kiểm kê bao gồm:
                </p>
                <div class="mb-5">
                    <b-row v-for="n in 3" :key="n">
                        <b-col class="font-size-print">Ông/bà :</b-col>
                        <b-col class="font-size-print">Chức vụ :</b-col>
                        <b-col class="font-size-print">Đai diện :</b-col>
                    </b-row>
                </div>
                <table v-if="productItems.length > 0" class="table table-bordered-print col-md-12 mb-10">
                    <tr>
                        <th class="label-color align-middle text-center font-size-print">STT</th>
                        <th class="label-color align-middle text-center font-size-print">MÃ HÀNG</th>
                        <th class="label-color align-middle text-center font-size-print">TÊN HÀNG</th>
                        <th class="label-color align-middle text-center font-size-print">IMEI</th>
                        <th class="label-color align-middle text-center font-size-print">ĐVT</th>
                        <th class="label-color align-middle text-center font-size-print">SL SỔ SÁCH</th>
                        <th class="label-color align-middle text-center font-size-print">SL THỰC TẾ</th>
                        <th class="label-color align-middle text-center font-size-print">CHÊNH LỆCH</th>
                        <th class="label-color align-middle text-center font-size-print">ĐƠN GIÁ</th>
                        <th class="label-color align-middle text-center font-size-print">THÀNH TIỀN</th>
                    </tr>
                    <tr v-for="(item, index) in productItems" :key="item.id">
                        <td class="text-center align-middle font-size-print">
                            {{ index + 1 }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ item.barCode }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ item.productName }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            <p v-if="item.productType === PRODUCT_TYPE.PRODUCT_IMEI" style="font-style: italic"
                                class="font-size-gift">
                                {{ item.productImei }}
                            </p>
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ item.unit }}

                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ item.totalQuantityInStock + item.totalQuantityHolding + item.totalQuantityWarranty }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ item.realStockQuantity }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ calcQuantityDeviations(item) }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ convertPrice(item.productPrice) }}
                        </td>
                        <td class="text-center align-middle font-size-print">
                            {{ convertPrice(calcTotalAmount(item)) }}
                        </td>
                    </tr>
                </table>
                <b-container class="bv-example-row">
                    <b-row cols="12">
                        <b-col md="4" class="text-center">
                            <div>
                                <p>
                                    <span class="label-color font-size-print">QL Cửa hàng</span>
                                </p>
                            </div>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <div>
                                <p class="text-center">
                                    <span class="label-color font-size-print">Kế toán</span>
                                </p>

                            </div>
                        </b-col>
                        <b-col md="4" class="text-center">
                            <div>
                                <p class="text-center">
                                    <span class="label-color font-size-print">Ban kiểm kê</span>
                                </p>
                            </div>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </b-container>
            </div>
        </b-row>
    </div>
</template>
<script>
import { convertPrice, formatDate } from '@/utils/common';
import { AMOUNT_TYPE, PRODUCT_TYPE, PAYMENT_TYPE } from '@/utils/enum';

export default {
    props: ['printData'],
    watch: {
        printData: {
            handler(newPrintData) {
                this.productStockCheck = newPrintData.productStockCheck;
                this.productItems = newPrintData.productItems
                this.stockDebtItems = newPrintData.stockDebtItems;
                const payments = newPrintData.payments || [];
                let cashAmount = 0;
                let transferAmount = 0;
                let totalMinusSalary = 0;
                let totaldeductedSalary = 0;

                payments.forEach((element) => {
                    if (element.paymentType === PAYMENT_TYPE.TRANSFER) {
                        transferAmount += element.paymentAmount
                    } else if (element.paymentType === PAYMENT_TYPE.CASH) {
                        cashAmount += element.paymentAmount
                    }
                });

                this.stockDebtItems.forEach((employee) => {
                    totaldeductedSalary += employee.paid;
                    totalMinusSalary += employee.totalAmount;
                });
                this.cashAmount = cashAmount;
                this.transferAmount = transferAmount;
                this.totalDebtAmout = this.productItems.reduce((total, product) => {
                    return total + product.debt;
                }, 0);
                this.totaldeductedSalary = totaldeductedSalary;
                this.totalMinusSalary = totalMinusSalary;
                this.totalPaidMoney = cashAmount + transferAmount + totalMinusSalary || 0;
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        convertPrice,
        formatDate,
        comvertTime(created) {
            const createdTime = this.formatDate(created, 'HH:mm DD-MM-YYYY')
            const [time, year] = createdTime.split(' ');
            const [hour, minutes] = time.split(':');
            const [day, month, years] = year.split('-')
            return `${hour} giờ ${minutes} ngày ${day} tháng ${month} năm ${years}`
        },
        calcQuantityDeviations(product) {
            return (
                product.realStockQuantity -
                (product.totalQuantityInStock +
                    product.totalQuantityHolding +
                    product.totalQuantityWarranty)
            );
        },
        calcTotalAmount(product) {
            return (
                product.productPrice * Math.abs(this.calcQuantityDeviations(product))
            );
        },
    },
    data() {
        return {
            DEBT_TYPE: {
                DEBT: 1,
                SUPPORT: 2,
            },
            PRODUCT_TYPE,
            AMOUNT_TYPE_MONEY: AMOUNT_TYPE.MONEY,
            AMOUNT_TYPE_PERCENT: AMOUNT_TYPE.PERCENT,
            productStockCheck: null,
            transferAmount: 0,
            productItems: [],
            stockDebtItems: [],
            cashAmount: 0,
            totalDebtAmout: 0,
            totalMinusSalary: 0,
            totalPaidMoney: 0,
            totaldeductedSalary: 0,
        };
    },
};
</script>
<style scoped>
.label-color {
    color: black;
    font-weight: 600;
}

.content-info {
    color: black;
    font-weight: 500;
}

.table-bordered-print th {
    border: 0.5px solid #474545;
}

.table-bordered-print td {
    border: 0.5px solid #474545;
}

.font-size-print {
    color: black;
    font-size: 13px;
}

.font-size-warranty {
    color: black;
    font-size: 13px;
}

.content-product {
    line-height: 1.375;
    white-space: pre-wrap;
    font-size: 13px;
    margin-top: 4px;
}

.font-size-gift {
    color: black;
    font-size: 12px;
}
</style>
